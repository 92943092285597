import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { ChkService } from '../../service/chk.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  public featuresList = [
    {header: 'Multi-level redundancy ', text: 'Data loggers are robust and have internal data storage redundancy to ensure data protection and security'},
    {header: 'Flexibility and ease of installation', text: 'Loggers easy to install and easy to implement thus reducing installation time'},
    {header: 'Always on', text: 'The logger-communication-modem-module do not require external power and only rely on an internal battery'},
    {header: 'Measurement point independence', text: 'Referring to point 3), the logger thus do not draw power from the point it is measuring. This also isolates the logger and modem from facility power preventing it being influenced or damaged by “dirty” power, surges and lightning'},
    {header: 'Ultra-low power consumption', text: 'Enables extended time periods of data recording and transmission without battery changes'},
    {header: 'Long-life battery', text: 'The logger has an ultra-long-life and high-redundancy battery with high energy density with absolute minimum charge decay'},
    {header: 'Integration capability and accommodate a wide range of sensors', text: 'M&V metering related equipment such as power & flow meters, CTs, pressure etc. '},
    {header: 'Redundant against harsh environments', text: 'The logger and battery are able to survive high environmental humidity and high temperatures'},
    {header: 'Data security', text: 'Important features include:', subItems: [
      {header: 'Contingency battery backup layer ', text: 'This enables the logger to continue record data for up to an hour, even when the battery is disconnected'},
      {header: 'Recorded data on the logger protected', text: 'even when battery power is totally lost'},
      {header: 'Data logging even with no remote communication', text: 'Internal data storage of a minimum of six months'},
      {header: 'Passcode protected', text: 'Prevents tampering'},
      {header: 'Transmitted data encrypted and protected', text: 'Securely viewable online. Data online hosted on cloud service - 99% up-time. Frequent database backups'},
    ]},
    {header: 'Clear and transparent data trail', text: 'Transparent data trail and quality management system on data gathering, transmission, processing and display.', subItems: [
      {header: 'M&V focus', text: 'SANAS compliant for 12L projects'},
      {header: 'Build on a M&V quality', text: 'management plan capturing full installing documentation, cross checks, commissioning and online data verification'},
    ]},
    {header: 'True reading', text: 'A true meter and logger display cross verification capability that can identify meter tampering'},
    {header: 'Variable data resolution', text: '1-min, 5-min, 10-min, 15-min, 30-min, hourly…'},
    {header: 'Onsite setup and communication verification without laptop or tablet connection', text: 'The logger display allows the following:', subItems: [
      {header: 'Instantaneous readings', text: 'for correct meter installation verification and comparison with a handheld check meter'},
      {header: 'On-logger data interpretation.', text: 'The logger is able to interpret, process and display data such as pulses, sensors, metering requiring multipliers or scaling factors'},
      {header: 'Data logging verification.', text: 'The installer is able to check on the display if the data logging is fully active and correctly set up'},
      {header: 'Remote communication check', text: 'and data upload verification. Installer able to check and verify signal strength and data transmission on the logger display'},
    ]},
    {header: 'Onsite calibrated system', text: 'Not only the physical meters should be calibrated, but the entire data trail. While the installer is on site, the system allows reading and data verification for calibration checks'},
    {header: 'Remote error reporting and diagnostics', text: 'Daily data and communication checks and error reporting to avoid data loss'},
    {header: 'Remote setting changes', text: 'Logger communication, sensor settings and setup options should be over-the-air remotely configurable. This allows post-installation corrections without the need for a site visit'},
    {header: 'Remote firmware updates ', text: 'System and logger remote firmware update functionality.  Updates and upgraded to achieve remote maintenance and effectively keep up data collection to reduce costly site visits and routine maintenance'},
    {header: 'Intelligent logger with advanced functions for specialised metering:',  subItems: [
      {text: 'The communication interval flexible and remotely settable to allow rapid communication during firmware upgrades or intensive monitoring periods'},
      {text: 'Accommodate M&V intelligence to monitor boundary conditions and provide alerts when these are breached'}
    ]},
    {header: 'Custom upgrades and feature additions', text: 'The logger platform and firmware allows for custom upgrades and changes to expand its reach to accommodate more sensors and functionality to address market needs'},
    {header: 'Online data access and display' , subItems: [
      {text: 'Online website where basic raw data feed, multiplication constants and processed data can be easily viewed and downloaded'},
      {text: 'Project interface to enable integration ability to view individual pump stations and multiple pump stations in a project'},
      {text: 'Meter point instead of logger management'},
      {text: 'Graphic display of data'},
      {text: 'Prompt self-identification diagnostics with display of meter operation or data transmission defects through faulty data feeds'},
    ]},
  ];



  constructor(    private pageTitleService: PageTitleService,
    private service: ChkService) {
            /* Page title */
            this.pageTitleService.setTitle('Services');

            /* Page subTitle */
            this.pageTitleService.setSubTitle(' Our Services');
    }



  ngOnInit() {

  }

}
