import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { ChkService } from '../../service/chk.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  /* Variables */
  services: any;
  about: any;
  team: any;
  contact: any;

  public aboutListLeft = [
    'Remote data gathering - AMR system',
    'Cost effective data gathering over project life',
    'Ultra low power devices for field metering - Robust loggers with ultra low power consumption and low maintenance – needs no external power supply',
    'Ease of installation and maintenance – quick connect and setup',
    'Low maintenance cost with remote condition diagnostics',
    'Metering and logging with specialised applications',
    'Online data display and processing',
  ];

  public aboutListRight = [
    'R&D centre with custom metering design and development. Electronic hardware, firmware and software design and consulting',
    '12L tax incentive ready metering – calibrated metering with onsite easy complete system calibration',
    'HVAC system performance monitoring',
    'Logging solutions optimised for M&V and energy auditing – perfect for CMVP and CEM temporary data logging requirements',
    '100% South African designed and manufactured – in addition 98% of  production components are sourced locally'
  ];

  constructor(private pageTitleService: PageTitleService, private service: ChkService) {

    /* Page title */
    this.pageTitleService.setTitle(' Know More About Us ');

    /* Page subTitle */
    this.pageTitleService.setSubTitle(' Find out what we do ');

    this.service.getServices().
      subscribe(response => { this.services = response; },
        err => console.log(err),
        () => this.services
      );

    this.service.getAbout().
      subscribe(response => { this.about = response; },
        err => console.log(err),
        () => this.about
      );

    this.service.getTeam().
      subscribe(response => { this.team = response; },
        err => console.log(err),
        () => this.team
      );

    this.service.getContactContent().
      subscribe(response => { this.contact = response; },
        err => console.log(err),
        () => this.contact
      );

  }



  ngOnInit() {
  }

  /*
  * Social links
  */
  // socialDetails: any = [
  //   { url: 'https://www.facebook.com/', icon: 'fa-facebook' },
  //   { url: '', icon: 'fa-twitter text-info' },
  //   { url: '', icon: 'fa-pinterest text-danger' },
  // ]

  /*
   * Classes of social ul, li
   */
  // socialsClasses: any = { ulClass: "", liClass: "", linkClass: "" }

}
