import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickModule } from 'ngx-slick';
import { DirectivesModule } from './core/directive/directives.module';

/* Routing */
import { AppRoutingModule } from './app-routing.module';

/* Service */
import { ChkService } from './service/chk.service';

/* components */
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './Pages/home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './Pages/contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';

import { MenuItems } from './core/menu/menu-items/menu-items';
import { MenuToggleModule } from './core/menu-toggle.module';
import { PageTitleService } from './core/page-title/page-title.service';
import { WidgetsModule } from './widgets/widgets.module';
import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './Pages/about/about.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent } from './support/support.component';
import { Footer2Component } from './footer2/footer2.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { ServicesComponent } from './Pages/services/services.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomeComponent,
    PricingComponent,
    ContactComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    FeaturesComponent,
    AboutComponent,
    SearchComponent,
    SupportComponent,
    Footer2Component,
    sidebarWidgetsComponent,
    ServicesComponent
  ],
  imports: [
    Ng4LoadingSpinnerModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    AppRoutingModule,
    WidgetsModule,
    MenuToggleModule,
    DirectivesModule,
    SlickModule.forRoot(),
    // RouterModule.forRoot([
    //   { path: '', redirectTo: '/home', pathMatch: 'full' },
    //   { path: 'home', component: HomeComponent },
    //   { path: 'about', component: AboutComponent },
    //   {path: 'contact', component: ContactComponent },
    //   { path: '', component: AppComponent }
    // ]),
    HttpClientModule
  ],
  providers: [
    MenuItems,
    PageTitleService,
    ChkService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
